import Fuse from "fuse.js";
import _ from "lodash";
import defaultState from "./defaultState";
import { DATA_SETS } from "../constants";
import {
  FETCH_STATIC_DATA_REQUEST,
  FETCH_STATIC_DATA_SUCCESS,
  FETCH_STATIC_DATA_ERROR,
  CLEAR_STATIC_DATA_CACHE
} from "../constants/actionConstants";

const DEFAULT_FUSE_OPTIONS = {
  keys: [
    "name",
    "name.period",
    "name.value",
    "name.translation",
    { name: "subsection", weight: 0.01 }
  ], // adding subsection to help with sorting of results
  distance: 50,
  minMatchCharLength: 2,
  threshold: 0.4,
  sortFn(a, b) {
    if (a.score === b.score) {
      // a.item[0] -> name
      if (_.isEqual(a.item[0], b.item[0])) {
        // a.item[4] -> subsection
        return a.item[4].v === "major_brand" ? -1 : 1;
      }
    }
    return a.score - b.score;
  }
};

export function mapSubsectionsToSearchList(subsections, isContext = false) {
  return subsections
    .filter(i => i.name !== "prr_retailer")
    .reduce(
      (prev, subsection) =>
        prev.concat(
          subsection.keywords.map(kw => ({
            name: [kw],
            subsection: subsection.name,
            table: isContext ? "context" : subsection.table
          }))
        ),
      []
    );
}

export function initSearchEngine(searchList, options) {
  return new Fuse(searchList, {
    ...DEFAULT_FUSE_OPTIONS,
    ...options
  });
}

export default (state = defaultState.data, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_REQUEST: {
      return {
        ...state,
        isFetchingData: true,
        isStaticData: false
      };
    }

    case FETCH_STATIC_DATA_SUCCESS: {
      // First time we're ready to set up fuzzy search engine
      const searchList = {};
      const searchEngine = {};
      DATA_SETS.forEach(i => {
        searchList[i] = mapSubsectionsToSearchList(action.subsections[i]);
        searchEngine[i] = initSearchEngine(searchList[i]);
      });
      return {
        ...state,
        searchEngine,
        searchList,
        isFetchingData: false,
        subsections: action.subsections,
        isStaticData: true,
        date: action.date,
        placeholder: action.placeholder
      };
    }

    case FETCH_STATIC_DATA_ERROR: {
      return {
        ...state,
        isFetchingData: false,
        isStaticData: false
      };
    }

    case CLEAR_STATIC_DATA_CACHE: {
      return {
        ...state,
        isFetchingData: false,
        isStaticData: false,
        subsections: [],
        searchEngine: {},
        searchList: [],
        placeholder: {},
        date: {}
      };
    }

    default: {
      return state;
    }
  }
};
