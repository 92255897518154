import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Button, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { getStoryConfig } from "../../config";
import { robot } from "../../images";

const PresentationError = props => {
  const { errorMessage, story } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4913136",
          formId: "6d098052-78cd-4f9b-a165-f7f6f0a7e90f",
          target: "#hubspotErrorForm"
        });
      }
    });
  }, []);
  return (
    <Box textAlign="center" sx={{ p: 5 }}>
      <img alt="robot" src={robot} />
      <Typography variant="h4" color="orange.main" sx={{ pt: 1 }}>
        Oops!
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ p: 1 }}
        gutterBottom
        id="error" // for load-runner
      >
        {errorMessage}
      </Typography>
      <Box sx={{ m: 3 }}>
        {story ? (
          <Link to={`/reports-reviews/new-${getStoryConfig(story).newLink}`}>
            <Button variant="outlined" color="primaryDark" disableElevation>
              Create a new report
            </Button>
          </Link>
        ) : (
          <Link to="/">
            <Button variant="outlined" color="primaryDark" disableElevation>
              Home
            </Button>
          </Link>
        )}
      </Box>
      <Paper elevation={3} sx={{ m: 5, p: 5 }} id="hubspotErrorForm" />
    </Box>
  );
};

PresentationError.propTypes = {
  errorMessage: PropTypes.string,
  story: PropTypes.string
};

PresentationError.defaultProps = {
  errorMessage: "An unknown error occured",
  story: ""
};

export default PresentationError;
