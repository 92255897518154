import React from "react";
import { Grid, Box, CircularProgress } from "@mui/material";

const styles = {
  root: { position: "relative" },
  bottom: { color: "#e5e0df" },
  top: {
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
    "& .MuiCircularProgress-circle": {
      strokeLinecap: "round"
    }
  }
};

const Loader = props => (
  <Grid container justifyContent="center">
    <Box sx={styles.root}>
      <CircularProgress
        variant="determinate"
        sx={styles.bottom}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={styles.top}
        {...props}
      />
    </Box>
  </Grid>
);

export default Loader;
