import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from "../constants/actionConstants";

export const addNotification = (
  message,
  level,
  vertical = "bottom",
  horizontal = "center"
) => ({
  type: ADD_NOTIFICATION,
  message,
  level,
  vertical,
  horizontal
});

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION
});
