import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  Stack,
  Button,
  FormHelperText,
  Typography,
  TextField,
  Link,
  ListItem,
  FormControl,
  Box,
  List,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import { forgotPasswordReset } from "../../actions";
import { Loader } from "../SharedComponents";
import { logoColour } from "../../images";

const ForgotPasswordReset = props => {
  const { submitForgotPasswordForm, error, isLoading } = props;
  const location = useLocation();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isError, setError] = useState(false);

  useEffect(() => {
    const string = location.search.slice(1);
    const urlParams = new URLSearchParams(string);
    setEmail(urlParams.get("email"));
    setToken(urlParams.get("token"));
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Box sx={{ m: 3, mt: 6, p: 2 }}>
        <img
          src={logoColour}
          alt="Interrodata"
          style={{ transform: "scale(0.95)" }}
        />
      </Box>
      <Grid item md={12}>
        <Typography variant="h4" color="secondary.dark" align="center">
          Reset password
        </Typography>
      </Grid>
      {activeStep === 0 && (
        <Grid item md={4}>
          <Stack spacing={2}>
            <ListItem disablePadding>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormControl>
            </ListItem>
            <ListItem disablePadding>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Confirm password"
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </ListItem>
            <Button
              variant="contained"
              color="primaryDark"
              disableElevation
              onClick={() => {
                setError(false);
                submitForgotPasswordForm(
                  email,
                  token,
                  password,
                  confirmPassword,
                  success => {
                    if (success) {
                      handleNext();
                    } else {
                      setError(true);
                    }
                  }
                );
              }}
            >
              Reset Password
            </Button>
            <List>
              <ListItem disablePadding disableGutters>
                <ListItemText secondary="Your password must:" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 20 }}>
                  {password.length < 8 ? "◦" : "✓"}
                </ListItemIcon>
                <ListItemText secondary="be at least 8 characters" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 20 }}>
                  {!password.match(/(?=.*[a-z])(?=.*[A-Z])/) ? "◦" : "✓"}
                </ListItemIcon>
                <ListItemText secondary="contain uppercase and lowercase characters" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 20 }}>
                  {!password.match(/(?=.*\d)/) ? "◦" : "✓"}
                </ListItemIcon>
                <ListItemText secondary="contain a number" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 20 }}>
                  {!password.match(
                    /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/
                  )
                    ? "◦"
                    : "✓"}
                </ListItemIcon>
                <ListItemText secondary="contain a special character" />
              </ListItem>
            </List>
            {isLoading && <Loader />}
            {error.length > 0 && !isError && (
              <FormHelperText error>{error}</FormHelperText>
            )}
            {isError && (
              <Stack>
                <FormHelperText error sx={{ textAlign: "center" }}>
                  {error}
                </FormHelperText>
                <Typography variant="body2" align="center" gutterBottom>
                  There was a problem resetting your password.
                </Typography>
                <Typography variant="body2" align="center">
                  Please check your email for the correct link or try again
                  using the forgot password option on the{" "}
                  <Link component={RouterLink} to="/login">
                    login page
                  </Link>
                  .
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
      )}
      {activeStep === 1 && (
        <Grid item md={6}>
          <Stack>
            <Typography variant="body2" align="center">
              Password reset successful. Please{" "}
              <Link component={RouterLink} to="/login">
                login here
              </Link>{" "}
              to continue.
            </Typography>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

ForgotPasswordReset.propTypes = {
  submitForgotPasswordForm: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool
};

ForgotPasswordReset.defaultProps = {
  submitForgotPasswordForm: () => {},
  error: "",
  isLoading: false
};

const mapStateToProps = state => ({
  error: state.user.forgotPasswordError,
  isLoading: state.user.isLoading
});

const mapDispatchToProps = dispatch => ({
  submitForgotPasswordForm: (
    email,
    token,
    password,
    confirmPassword,
    onComplete
  ) =>
    dispatch(
      forgotPasswordReset(email, token, password, confirmPassword, onComplete)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordReset);
