import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Input,
  InputAdornment,
  FormHelperText,
  FormControl
} from "@mui/material";
import { DateRange } from "@mui/icons-material";

export default class CustomDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, onClick, label, disabled, helperText } = this.props;
    return (
      <FormControl sx={{ p: 1, width: 200 }}>
        <Input
          error={!!helperText}
          placeholder={label}
          disabled={disabled}
          autoComplete="off"
          onClick={onClick}
          value={value}
          startAdornment={
            <InputAdornment position="start">
              <DateRange color={disabled ? "disabled" : "inherit"} />
            </InputAdornment>
          }
        />
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

CustomDateInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool
};

CustomDateInput.defaultProps = {
  onClick: () => {},
  value: "",
  label: "",
  helperText: "",
  disabled: false
};
