import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Collapse,
  Autocomplete,
  ListItem,
  Typography,
  Dialog
} from "@mui/material";
import {
  PersonOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  PeopleOutlineOutlined
} from "@mui/icons-material";
import ParticleComponent from "./ParticleComponent";
import {
  signIn,
  getClients,
  forgotPassword,
  clearSettingsMessages
} from "../../actions";
import Helpdesk from "./Helpdesk";
import ForgotPassword from "./ForgotPassword";
import { Loader } from "../SharedComponents";
import { logo } from "../../images";
import "./Login.css";

const styles = {
  input: { py: 1, color: "white", fontSize: 14 },
  autocomplete: { py: "8px !important" }
};

const Login = props => {
  const {
    isUser,
    error,
    submitSignInForm,
    isLoading,
    location,
    clients,
    getClientOptions,
    submitForgotPasswordForm,
    clearErrors
  } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [client, setClient] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => getClientOptions(), []);
  const { state } = location;
  const { from } = state || { from: { pathname: "/" } };

  if (isUser) {
    return <Redirect to={from} />;
  }
  return (
    <div className="Login">
      <Grid container direction="column" alignItems="center">
        <Grid item md={12} sx={{ mt: 20, mb: 4 }}>
          <img alt="Interrodata" src={logo} />
        </Grid>
        <Grid item md={12} sx={{ width: 400 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              submitSignInForm(email, password, client);
            }}
          >
            <ListItem>
              <FormControl fullWidth>
                <Input
                  sx={styles.input}
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonOutlined htmlColor="white" />
                    </InputAdornment>
                  }
                  fullWidth
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <Input
                  sx={styles.input}
                  placeholder="Password"
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockOutlined htmlColor="white" />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        onMouseDown={e => e.preventDefault()}
                      >
                        {isPasswordVisible ? (
                          <VisibilityOutlined htmlColor="white" />
                        ) : (
                          <VisibilityOffOutlined htmlColor="white" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                />
              </FormControl>
            </ListItem>
            <Collapse in={email.includes("@interrodata.com")}>
              <ListItem>
                <FormControl fullWidth>
                  <Autocomplete
                    id="client-select" // for load-runner
                    onChange={(e, newValue) => setClient(newValue)}
                    options={clients}
                    renderInput={params => (
                      <Input
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        sx={{ ...styles.input, ...styles.autocomplete }}
                        placeholder="Client"
                        name="client"
                        value={client}
                        startAdornment={
                          <InputAdornment position="start">
                            <PeopleOutlineOutlined htmlColor="white" />
                          </InputAdornment>
                        }
                        fullWidth
                      />
                    )}
                    disablePortal
                  />
                </FormControl>
              </ListItem>
            </Collapse>
            <ListItem>
              <Grid container justifyContent="space-between">
                <Button disableElevation onClick={() => setOpen(true)}>
                  Forgot Password
                </Button>
                <Button
                  variant="contained"
                  color="primaryDark"
                  type="sumbit"
                  disableElevation
                >
                  Submit
                </Button>
              </Grid>
            </ListItem>
          </form>
        </Grid>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="xs"
          fullWidth
        >
          <ForgotPassword
            forgotPassword={submitForgotPasswordForm}
            onClose={() => {
              setOpen(false);
              clearErrors();
            }}
          />
        </Dialog>
        <ListItem>
          <Grid container justifyContent="center">
            {error.length > 0 && (
              <Typography variant="subtitle2" color="red">
                {error.startsWith("ERROR:") ? error.substring(12) : error}
              </Typography>
            )}
          </Grid>
        </ListItem>
        {isLoading ? <Loader /> : null}
        <Helpdesk />
      </Grid>
      <ParticleComponent />
    </div>
  );
};

Login.propTypes = {
  submitSignInForm: PropTypes.func,
  isUser: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  location: PropTypes.shape(),
  getClientOptions: PropTypes.func,
  clients: PropTypes.arrayOf(PropTypes.string),
  submitForgotPasswordForm: PropTypes.func,
  clearErrors: PropTypes.func
};

Login.defaultProps = {
  submitSignInForm: () => {},
  isUser: false,
  error: "",
  isLoading: false,
  location: {},
  getClientOptions: () => {},
  clients: [],
  submitForgotPasswordForm: () => {},
  clearErrors: () => {}
};

const mapStateToProps = state => ({
  isUser: state.user.isUser,
  error: state.user.error,
  isLoading: state.user.isLoading,
  clients: state.user.clients
});

const mapDispatchToProps = dispatch => ({
  submitSignInForm: (email, password, client) => {
    dispatch(signIn(email, password, client));
  },
  getClientOptions: () => dispatch(getClients()),
  submitForgotPasswordForm: (email, onComplete) =>
    dispatch(forgotPassword(email, onComplete)),
  clearErrors: () => dispatch(clearSettingsMessages())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
