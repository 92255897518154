import _ from "lodash";
import {
  ENTER_SEARCH_TERM,
  DELETE_LAST_SEARCH_TERM,
  DELETE_TERM,
  ENTER_SELECTED_TERM,
  ENTER_AUTOCOMPLETE_TERM,
  SEARCH_REPORT_HINT,
  CLEAR_SEARCH_REPORT_HINT,
  FILTER_STATIC_DATA_REQUEST,
  FILTER_STATIC_DATA_ERROR,
  FILTER_STATIC_DATA_SUCCESS,
  REMOVE_STATIC_DATA_FILTER,
  CLEAR_ALL_SEARCH_DATA,
  CLEAR_STORY_SEARCH_DATA,
  GET_CONTEXT_REQUEST,
  GET_CONTEXT_SUCCESS,
  GET_CONTEXT_ERROR,
  REMOVE_CONTEXT
} from "../constants/actionConstants";
import { FILTER_STATIC_DATA_URL, CONTEXT_URL, API_KEY } from "../constants";

/**
 * Natural language input breakwords. Identified in the searchTerms with
 * table = "none", subsection = "none"
 * @type {Array}
 */
const delimiters = [
  "in",
  "vs",
  "performance",
  "perform",
  "of",
  "from",
  "for",
  "during",
  "how",
  "did",
  "within"
];

/**
 * A regex derived from the list of delimiters.
 * @type {RegExp}
 */
const delimiterRegex = new RegExp(`^\\b(${delimiters.join("|")})\\b`, "i");

/**
 * Action for when a user enters a search string.
 * Any prepositions/breakwords are separated from the search term, then it is
 * joined onto the list of search terms in state.
 * @param  {String} searchTerm User search input
 * @return {Object}            Object to be used by the dispatch function.
 */
export const enterSearchTerm = (searchTerm, searchEngine, story) => {
  const delimitedSearchTerms = searchTerm
    .replace(delimiterRegex, match => `,${match},`) // add commas between delimiters
    .split(",") // split using said commas
    .filter(s => s.length > 0) // remove any empty strings
    .map(term => ({ name: term, subsection: "none", table: "none" })); // convert to search term obj
  const last = delimitedSearchTerms.length - 1; // idx of last search term

  // mark last element as the search term.
  if (delimitedSearchTerms.length > 0) {
    delimitedSearchTerms[last].subsection = "autocomplete";
    delimitedSearchTerms[last].name = delimitedSearchTerms[last].name.replace(
      /^\s*/,
      ""
    );
  }
  return {
    type: ENTER_SEARCH_TERM,
    suggestions: delimitedSearchTerms,
    searchEngine,
    story
  };
};

/**
 * Action for when the user deletes the last search term (i.e. pressing
 * backspace when the last element is a completed search term.)
 * @return {Object} Object to be used by the dispatch function
 */
export const deleteLastSearchTerm = story => ({
  type: DELETE_LAST_SEARCH_TERM,
  story
});

/**
 * Action for when the user deletes a term from the search bar.
 * @param  {Object} term The term to be deleted
 * @return {Object}      Object to be used by the dispatch function
 */
export const deleteTerm = (term, story) => ({
  type: DELETE_TERM,
  term,
  story
});

/**
 * Action for when the user selects/clicks on an option to add to
 * the list of search terms
 * @param  {Object} selected   The selected search term
 * @return {Object}            Object to be used by the dispatch function
 */
export const enterSelectedTerm = (selected, story) => ({
  type: ENTER_SELECTED_TERM,
  selectedTerm: selected,
  story
});

/**
 * Action for when the user selects/clicks on an autocomplete term to add to
 * the list of search terms
 * @param  {Object} suggestion The autocompleted search term
 * @return {Object}            Object to be used by the dispatch function
 */
export const enterAutocompleteTerm = (suggestion, story) => ({
  type: ENTER_AUTOCOMPLETE_TERM,
  suggestion,
  story
});

export const checkSearchHints = hint => ({
  type: SEARCH_REPORT_HINT,
  hint
});

export const clearSearchHints = () => ({
  type: CLEAR_SEARCH_REPORT_HINT
});

function filterStaticDataRequest() {
  return {
    type: FILTER_STATIC_DATA_REQUEST
  };
}

function filterStaticDataSuccess(data, terms, story) {
  return {
    type: FILTER_STATIC_DATA_SUCCESS,
    data,
    filterTerms: terms,
    story
  };
}

function filterStaticDataError(error) {
  return {
    type: FILTER_STATIC_DATA_ERROR,
    error
  };
}

function removeFilter(story) {
  return {
    type: REMOVE_STATIC_DATA_FILTER,
    story
  };
}

export const filterStaticData =
  (term, filterTerms = [], story, client) =>
  async dispatch => {
    dispatch(filterStaticDataRequest());
    const terms = filterTerms.concat([term]);
    try {
      const res = await fetch(FILTER_STATIC_DATA_URL, {
        method: "post",
        body: JSON.stringify({ items: terms, story, client }),
        headers: { "X-API-KEY": API_KEY }
      });
      if (!res.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await res.json();
      return dispatch(filterStaticDataSuccess(data, terms, story));
    } catch (err) {
      return dispatch(filterStaticDataError(err.message));
    }
  };

export const removeFilterTerm =
  (term, filterTerms, story, client) => async dispatch => {
    const terms = filterTerms.filter(t => !_.isEqual(t, term));
    if (terms.length === 0) {
      return dispatch(removeFilter(story));
    }
    dispatch(filterStaticDataRequest());
    try {
      const res = await fetch(FILTER_STATIC_DATA_URL, {
        method: "post",
        body: JSON.stringify({ items: terms, story, client }),
        headers: { "X-API-KEY": API_KEY }
      });
      if (!res.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await res.json();
      return dispatch(filterStaticDataSuccess(data, terms, story));
    } catch (err) {
      return dispatch(filterStaticDataError(err.message));
    }
  };

export const clearAllSearchData = () => ({
  type: CLEAR_ALL_SEARCH_DATA
});

export const clearStorySearchData = story => ({
  type: CLEAR_STORY_SEARCH_DATA,
  story
});

function getContextRequest() {
  return {
    type: GET_CONTEXT_REQUEST
  };
}

function getContextSuccess(data, story) {
  return {
    type: GET_CONTEXT_SUCCESS,
    data,
    story
  };
}

function getContextError(error) {
  return {
    type: GET_CONTEXT_ERROR,
    error
  };
}

function removeContext(story) {
  return {
    type: REMOVE_CONTEXT,
    story
  };
}

export const getContext =
  (terms, context, type, story, client, isInitial = false) =>
  async dispatch => {
    if (terms.length === 0) {
      return dispatch(removeContext(story));
    }
    dispatch(getContextRequest());
    try {
      const res = await fetch(CONTEXT_URL, {
        method: "post",
        body: JSON.stringify({ [type]: terms, context, type, story, client }),
        headers: { "X-API-KEY": API_KEY }
      });
      if (!res.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await res.json();
      if (isInitial && data.defaultContext) {
        const term = {
          name: data.defaultContext.keywords[0].value
            ? data.defaultContext.keywords[0].value
            : data.defaultContext.keywords[0],
          subsection: data.defaultContext.name,
          table: "context",
          story
        };
        dispatch(enterSelectedTerm(term, story));
      }
      return dispatch(getContextSuccess(data, story));
    } catch (err) {
      return dispatch(getContextError(err.message));
    }
  };
