import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Link
} from "@mui/material";
import { ChangePasswordIcon } from "../Icons";

const ForgotPassword = props => {
  const { forgotPassword, onClose } = props;
  const [email, setEmail] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Box textAlign="center">
      <DialogTitle>
        <ChangePasswordIcon fontSize="small" color="primary" />
        <Typography variant="subtitle2" component="p" color="primary">
          Forgot password
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {activeStep === 0 && (
            <Grid item md={12}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
              />
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid item sx={{ pt: 2, textAlign: "left" }}>
              <Typography variant="body2" gutterBottom>
                Thank you for resetting your password. If the email address is
                in our database, we will send you an email to reset your
                password. Please check your spam if it hasn&apos;t been
                delivered in the next 5 minutes.
              </Typography>
              <Typography variant="body2" gutterBottom>
                We recommend saving this password to a password manager to make
                it easier to login next time.
              </Typography>
              <Typography variant="body2" gutterBottom>
                You can also always contact us at{" "}
                <Link href="mailto:helpdesk@interrodata.com">
                  helpdesk@interrodata.com
                </Link>{" "}
                for any further issues!
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mx: 2, mb: 2 }}>
        {activeStep === 0 && (
          <Grid container justifyContent="space-between">
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                forgotPassword(email, handleNext);
              }}
            >
              Reset Password
            </Button>
          </Grid>
        )}
        {activeStep === 1 && (
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => onClose()}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Box>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func,
  onClose: PropTypes.func
};

ForgotPassword.defaultProps = {
  forgotPassword: () => {},
  onClose: () => {}
};

export default ForgotPassword;
