import React from "react";
import { Box, Button } from "@mui/material";
import { ContactSupport } from "@mui/icons-material";

const Helpdesk = () => (
  <Box sx={{ position: "fixed", bottom: 40, right: 40 }}>
    <Button
      variant="contained"
      sx={{
        bgcolor: "white",
        color: "secondary.dark",
        "&:hover": { bgcolor: "white" }
      }}
      startIcon={<ContactSupport />}
      disableElevation
      href="https://share.hsforms.com/1JBh490lnRS6bBFd0QnZ5AA2xb00"
      rel="noopener noreferrer"
      target="_blank"
    >
      Contact support
    </Button>
  </Box>
);

export default Helpdesk;
